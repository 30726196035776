@import "./variables";

@mixin upXs() {
  @media (orientation: portrait) and (min-width: $sMinWidth),
    (orientation: landscape) and (min-width: $sMinWidth) and (min-height: $sMinHeight) {
    @content;
  }
}

@mixin xs() {
  @media (orientation: portrait) and (max-width: $xsMaxWidth),
    (orientation: landscape) and (max-width: $xsMaxWidth) and (max-height: $xsMaxHeight) {
    @content;
  }
}

@mixin s() {
  @media (max-width: $sMaxWidth) and (min-width: $sMinWidth) and (orientation: portrait),
    (max-width: $sMaxWidth) and (min-width: $sMinWidth) and (min-height: $sMinHeight) and (orientation: landscape) {
    @content;
  }
}

@mixin m() {
  @media (min-width: $mMinWidth) and (max-width: $mMaxWidth) {
    @content;
  }
}

@mixin upS() {
  @media (min-width: $sMaxWidth) {
    @content;
  }
}

@mixin upM() {
  @media (min-width: $mMaxWidth) {
    @content;
  }
}

@mixin downM() {
  @media (max-width: $mMinWidth  - 1px) {
    @content;
  }
}

@mixin mAndDown() {
  @media (max-width: $mMinWidth) {
    @content;
  }
}

@mixin mAndUp() {
  @media (min-width: $mMinWidth) {
    @content;
  }
}

@mixin downL() {
  @media (max-width: $lMinWidth - 1px) {
    @content;
  }
}

@mixin l() {
  @media (min-width: $lMinWidth) {
    @content;
  }
}

@mixin upL() {
  @media (max-width: $lMinWidth) {
    @content;
  }
}