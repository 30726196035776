@import "../../../styles/mixins";

.slideAndFade {
  width: 100%;
  animation: slide 500ms 1;
}

@keyframes slide {
  0% {
    transform: translate(0, 200px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@include xs() {
  @keyframes slide {
    0% {
      transform: translate(0, 100px);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}
