@import "../../../styles/variables";

.link {
  display: flex;

  align-items: center;
  text-decoration: none;
}

.logoLink {
  margin-right: auto;
}

.sponsorImg {
  height: 24px;
}


.content {
  flex-grow: 1;
  align-self: stretch;

  font-weight: 500;
  font-size: 17px;
  line-height: 1.18;

  text-align: center;
  letter-spacing: 0.377778px;
}

.friezeLogoWrapper,
.controlsWrapper {
  width: 20%;
}

.controlsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.sponsorLink {
  margin-left: 41px;
}

@media (max-width: 1350px) {
  .root {
    padding-left: 15px;
    padding-right: 15px;
  }

  .sponsorLink {
    margin-left: 21px;
  }
  .dbLogo {
    width: 130px;
  }
}
